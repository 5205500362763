import {bindForm, serialize} from "./bindForm";
import {validate}            from "./validate";
import {closeModal}          from "./modal";

const debounce = (func) => {
    let timer;
    return function (event) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(func, 100, event);
    };
}

const handleBurgerClick = () => {
    document.querySelector('.header__burger').classList.toggle('header__burger_close');
    document.querySelector('.header__menu').classList.toggle('header__menu_open');
}

const bindHeaderMobile = () => {
    document.querySelector('.js-header-burger').addEventListener('click', handleBurgerClick);
}

const bindBrandsSwiper = (node) => {
    if (!node) {
        return false;
    }

    new Swiper(node, {
        slidesPerView: 'auto',
        spaceBetween: 30,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    })
}

const bindCtoSlider = (node) => {
    if (!node) {
        return false;
    }

    const mainGallery = node.querySelector('.cte__gallery-main .swiper');
    const thumbsGallery = node.querySelector('.cte__gallery-thumbs .swiper');

    const swiperThumbs = thumbsGallery ? new Swiper(thumbsGallery, {
        spaceBetween: 12,
        slidesPerView: 5,
        slideToClickedSlide: true,
        pagination: {
            el: '.cte__gallery-thumbs-pagination',
            clickable: true,
        },
        breakpoints: {
            1551: {
                spaceBetween: 12,
                slidesPerView: 5,
            },
            1251: {
                spaceBetween: 12,
                slidesPerView: 4,
            },
            901: {
                spaceBetween: 12,
                slidesPerView: 5,
            },
            738: {
                spaceBetween: 12,
                slidesPerView: 4,
            },
            0: {
                spaceBetween: 12,
                slidesPerView: 'auto',
            }
        }
    }) : null;

    const swiperMain = new Swiper(mainGallery, {
        spaceBetween: 12,
        thumbs: {
            swiper: swiperThumbs,
        },
    });
}

const bindSchedule = (node) => {
    if (!node) {
        return false;
    }

    const items = [...node.querySelectorAll('.schedule__date')];
    if (!items.length) {
        return false;
    }

    const buttons = [...node.querySelectorAll('.schedule__date-button')];
    if (!buttons.length) {
        items.map(item => {
            item.classList.add('schedule__date_show');
        })
        return false;
    }

    buttons.map(button => {
        button.addEventListener('click', (e) => {
            e.preventDefault();
            if (window.matchMedia('(min-width: 738px)').matches) {
                items.map(item => {
                    item.classList.toggle('schedule__date_show');
                })
                buttons.map(button => {
                    button.innerText = button.innerText === 'Далее' ? 'Свернуть' : 'Далее';
                })
            } else {
                button.innerText = button.innerText === 'Далее' ? 'Свернуть' : 'Далее';
                button.closest('.schedule__date').classList.toggle('schedule__date_show');
            }
        })
    })
}

const bindSimpleGallery = (node) => {
    if (!node) {
        return false;
    }

    new Swiper(node, {
        slidesPerView: 3,
        spaceBetween: 44,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            0: {
                slidesPerView: 'auto',
                pagination: false,
                spaceBetween: 20,
            },
            738: {
                slidesPerView: 2,
                spaceBetween: 30,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                }
            },
            1201: {
                slidesPerView: 3,
            }
        }
    })

}

const bindTransportGuide = (button) => {
    if (!button) {
        return false;
    }

    const guide = button.parentElement.parentElement.parentElement.querySelector('.transport__item-guide');

    if (!guide) {
        return false;
    }

    button.addEventListener('click', (e) => {
        e.preventDefault();

        button.classList.toggle('btn_icon-chevron-open');
        guide.classList.toggle('transport__item-guide_show');
    })
}

const bindMapAction = (node) => {
    if (!node) {
        return false;
    }

    node.addEventListener('click', (e) => {
        e.preventDefault();
        node.classList.add('map__iframe_active');
    })
}

const checkVote = () => {

    fetch('/ajax/vote.php', {
        method: 'GET',
    }).then((answer) => answer.json())
        .then(({tokar, frezer}) => {
            const tokarNode = document.getElementById('tokarVote');
            const frezerNode = document.getElementById('frezerVote');

            if (tokarNode && frezerNode && tokar && frezer) {
                tokarNode.innerText = String(tokar);
                frezerNode.innerText = String(frezer)
            }
        }).catch((e) => {
    })

    if (window.localStorage.getItem('alreadyVoted')) {
        const text = document.querySelector('.js-vote-text');
        if (text) {
            text.innerText = 'Вы уже приняли участие в голосовании. Успехов!'
        }

        for (const btn of document.querySelectorAll('.js-btn-vote')) {
            btn.remove();
        }
    }
}

const voteHandler = (form) => {

    if (!validate(form)) {
        return false;
    }
    form.classList.add('form_loading');
    // отправляем данные
    const body = form.getAttribute("enctype") === "multipart/form-data" ? new FormData(form) : serialize(form);
    const headers = form.getAttribute("enctype") === "multipart/form-data" ? {} : {
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept": 'application/json',
    };
    const options = {
        method: 'POST',
        body,
        headers,
    }
    fetch(form.getAttribute('action'), options)
        .then((answer) => answer.json())
        .then((answer) => {
            const formMessage = document.createElement('div');
            formMessage.classList.add('form__message');
            formMessage.innerHTML = answer.message ? answer.message : '';
            form.append(formMessage);
            form.classList.remove('form_loading');
            if (answer.status === 'success') {
                customVote(form.querySelector('[name="PROPERTY[VALUE]"]').value);
                checkVote();
                if (form.getAttribute('data-success')) {
                    eval(form.getAttribute('data-success'));
                }
                formMessage.classList.add('form__message_success');
                setTimeout(() => {
                    form.reset();
                }, 100);
                setTimeout(() => {
                    closeModal();
                }, 8000);

            } else {
                formMessage.classList.add('form__message_error');
                setTimeout(() => {
                    formMessage.remove();
                }, 8000);
            }
            form.addEventListener('click', () => {
                closeModal();
            })
        })
        .catch((message) => {
            console.warn(message);
            const formMessage = document.createElement('div');
            formMessage.classList.add('form__message');
            formMessage.classList.add('form__message_error');
            formMessage.innerHTML = "Ошибка связи с сервером.<br>Попробуйте ещё раз или свяжитесь с нами по телефону <a href='tel:88005058944'>8 (800) 505-89-44</a>";
            form.append(formMessage);
            form.classList.remove('form_loading');
            setTimeout(() => {
                formMessage.remove();
            }, 10000);
            form.addEventListener('click', () => {
                closeModal();
            })
        });
}

const customVote = (value) => {
    if (!value) {
        return false;
    }

    let valueNode;

    if (value === 'tokar') {
        valueNode = document.getElementById('tokarVote');
    }
    if (value === 'frezer') {
        valueNode = document.getElementById('frezerVote');
    }
    if (valueNode) {
        valueNode.innerText = String(Number(valueNode.innerText) + 1)
    }

    window.localStorage.setItem('alreadyVoted', '1');
}

const bindGoals = (yaID = 90164719) => {
    const regButtons = document.querySelectorAll('.js-register, .js-header-register');

    for (const regButton of regButtons) {
        regButton.addEventListener('click', () => {
            ym(yaID, 'reachGoal', 'registration')
        })
    }

    const phoneLinks = document.querySelectorAll('a[href^="tel:8800"]');

    for (const phoneLink of phoneLinks) {
        phoneLink.addEventListener('click', () => {
            ym(yaID, 'reachGoal', 'phone')
        })
    }

    const machinesListButtons = document.querySelectorAll('.js-list-button');

    for (const machinesListButton of machinesListButtons) {
        machinesListButton.addEventListener('click', () => {
            ym(yaID, 'reachGoal', 'list')
        })
    }

    const emails = document.querySelectorAll('.js-mail');

    for (const email of emails) {
        email.addEventListener('click', () => {
            ym(yaID, 'reachGoal', 'post')
        })
    }
}

const bindMachineryList = (node) => {
    if (!node) {
        return false;
    }

    const button = node.querySelector('.front-block__button');

    if (!button) {
        return false;
    }

    const list = node.querySelector('.machinery-list');
    if (!list) {
        return false;
    }

    const items = [...node.querySelectorAll('li')];
    const buttonTextNode = button.querySelector('span');

    const adaptiveShow = () => {
        if (window.matchMedia('(min-width: 1551px)').matches) {
            items.map((item, index) => {
                if (index < 8) {
                    item.classList.remove('machinery-list__to-hide');
                } else {
                    item.classList.add('machinery-list__to-hide');
                }
            })
        }
        if (window.matchMedia('(min-width: 1051px) and (max-width: 1550px)').matches) {
            items.map((item, index) => {
                if (index < 9) {
                    item.classList.remove('machinery-list__to-hide');
                } else {
                    item.classList.add('machinery-list__to-hide');
                }
            })
        }
        if (window.matchMedia('(min-width: 651px) and (max-width: 1050px)').matches) {
            items.map((item, index) => {
                if (index < 8) {
                    item.classList.remove('machinery-list__to-hide');
                } else {
                    item.classList.add('machinery-list__to-hide');
                }
            })
        }
        if (window.matchMedia('(max-width: 650px)').matches) {
            items.map((item, index) => {
                if (index < 4) {
                    item.classList.remove('machinery-list__to-hide');
                } else {
                    item.classList.add('machinery-list__to-hide');
                }
            })
        }

    }

    adaptiveShow();

    window.addEventListener('resize', debounce(adaptiveShow));


    button.addEventListener('click', (e) => {
        e.preventDefault();
        buttonTextNode.innerText = buttonTextNode.innerText === 'Все станки' ? 'Свернуть' : 'Все станки';
        button.classList.toggle('front-block__button_open');

        list.classList.toggle('machinery-list_show');
    })

}

window.addEventListener('load', () => {

    bindBrandsSwiper(document.querySelector('.js-brands'));
    bindCtoSlider(document.querySelector('.js-cte-gallery'));
    bindSchedule(document.querySelector('.schedule__content'));
    bindHeaderMobile();

    for (const simpleGallery of document.querySelectorAll('.js-simple-gallery .swiper')) {
        bindSimpleGallery(simpleGallery)
    }

    for (const transportButton of document.querySelectorAll('.js-transport-guide-button')) {
        bindTransportGuide(transportButton);
    }

    bindMapAction(document.querySelector('.map__iframe'));

    checkVote();

    bindMachineryList(document.querySelector('.js-machinery-list'));

    if (window.location.hostname === 'metobr-kami.ru') {
        bindGoals();
    }

    const forms = document.querySelectorAll('.js-form');
    for (const form of forms) {
        bindForm(form);
    }

    const voteForms = document.querySelectorAll('.js-form-vote');
    for (const form of voteForms) {
        bindForm(form, voteHandler);
    }
})